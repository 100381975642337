<template>
	<ul class="nav nav-pills tab-inicio flex-column">
		<li class="uib-tab nav-item">
			<a href="#" :class="{'nav-link':true, 'active':tabIndex==0}" v-on:click.prevent="tabIndexClick(0)">
				Beneficios
			</a>
		</li>
		<li class="uib-tab nav-item">
			<a href="#" :class="{'nav-link':true, 'active':tabIndex==1}" v-on:click.prevent="tabIndexClick(1)">
				¿Cómo funciona?
			</a>
		</li>
		<li class="uib-tab nav-item">
			<a href="#" :class="{'nav-link':true, 'active':tabIndex==2}" v-on:click.prevent="tabIndexClick(2)">
				Requisitos
			</a>
		</li>
		<li class="uib-tab nav-item">
			<a href="#" :class="{'nav-link':true, 'active':tabIndex==3}" v-on:click.prevent="tabIndexClick(3)">
				Las historias de nuestros clientes
			</a>
		</li>
		<li class="uib-tab nav-item">
			<a href="#" :class="{'nav-link':true, 'active':tabIndex==4}" v-on:click.prevent="tabIndexClick(4)">
				Preguntas frecuentes
			</a>
		</li>
		<li class="uib-tab nav-item">
			<a href="#" :class="{'nav-link':true, 'active':tabIndex==5}" v-on:click.prevent="tabIndexClick(5)">
				Video informativo
			</a>
		</li>
		<li class="bd-white">
			<div v-show="tabIndex==0">
				<div class="py-3 px-3 bg-white">
					<h1 class="text-center mb-3">Beneficios</h1>
					<div class="card">
						<a href="#" class="card-header" v-on:click.prevent="tabClick('tab0', 'beneficios')" style="font-weight: 500;">
							<span class="text-inversion font-weight-bold mr-2">01</span> <strong>Buenos rendimientos:</strong>
							<span class="material-icons float-right" v-if="beneficios.tab0">expand_more</span>
							<span class="material-icons float-right" v-else>keyboard_arrow_right</span>
						</a>
						<div v-if="beneficios.tab0" class="card-body">
							Es muy rentable, puedes obtener retornos de hasta 14.11% anual.
						</div>
					</div>
					<div class="card">
						<a href="#" class="card-header rounded-0" v-on:click.prevent="tabClick('tab1', 'beneficios')" style="font-weight: 500;">
							<span class="text-inversion font-weight-bold mr-2">02</span> <strong>Accesible:</strong>
							<span class="material-icons float-right" v-if="beneficios.tab1">expand_more</span>
							<span class="material-icons float-right" v-else>keyboard_arrow_right</span>
						</a>
						<div v-if="beneficios.tab1" class="card-body">
							Hay inversiones para todos.
						</div>
					</div>
					<div class="card">
						<a href="#" class="card-header rounded-0" v-on:click.prevent="tabClick('tab2', 'beneficios')" style="font-weight: 500;">
							<span class="text-inversion font-weight-bold mr-2">03</span> <strong>Transparente:</strong>
							<span class="material-icons float-right" v-if="beneficios.tab2">expand_more</span>
							<span class="material-icons float-right" v-else>keyboard_arrow_right</span>
						</a>
						<div v-if="beneficios.tab2" class="card-body">
							Sabes exactamente el destino, plazo, retorno y riesgo de tu inversión.
						</div>
					</div>
					<div class="card">
						<a href="#" class="card-header rounded-0" v-on:click.prevent="tabClick('tab3', 'beneficios')" style="font-weight: 500;">
							<span class="text-inversion font-weight-bold mr-2">04</span> <strong>Sencillo:</strong>
							<span class="material-icons float-right" v-if="beneficios.tab3">expand_more</span>
							<span class="material-icons float-right" v-else>keyboard_arrow_right</span>
						</a>
						<div v-if="beneficios.tab3" class="card-body">
							Todo es online, tú inviertes y clubcashin.com se encarga del resto.
						</div>
					</div>
					<div class="card">
						<a href="#" class="card-header" v-on:click.prevent="tabClick('tab4', 'beneficios')" style="border-radius: 0px 0px 24px 24px; font-weight: 500;">
							<span class="text-inversion font-weight-bold mr-2">05</span> <strong>Seguro:</strong>
							<span class="material-icons float-right" v-if="beneficios.tab4">expand_more</span>
							<span class="material-icons float-right" v-else>keyboard_arrow_right</span>
						</a>
						<div v-if="beneficios.tab4" class="card-body">
							Tu inversión siempre estará garantizada por un activo real que vale al menos el doble del monto invertido. 
						</div>
					</div>
					<div class="text-center mt-3">
						<router-link :to="{name:'opciones-inversion.index'}" class="btn btn-primary rounded-pill font-weight-bold">
							¡Empieza a Invertir!
						</router-link>
					</div>
				</div>
			</div>
			<div v-show="tabIndex==1">
				<div class="py-3">
					<h1 class="text-center mb-3">¿Cómo funciona?</h1>
					<div class="row justify-content-between align-items-center">
						<div class="col-md-12">
							<ul class="pl-0">
								<li class="d-flex align-items-center bg-white rounded mb-2 px-md-5 px-md-2 py-3" style="font-size: 1.2rem;">
									<h3 width="20px" class="font-weight-bold mb-0 ml-2">01</h3>
									<div style="display: block; width: 5px; height: 5px;" class="bg-inversion mx-3 rounded-circle"></div>
									Regístrate con tu correo electrónico y llena tu perfil
								</li>
								<li class="d-flex align-items-center bg-white rounded mb-2 px-md-5 px-md-2 py-3" style="font-size: 1.2rem;">
									<h3 width="20px" class="font-weight-bold mb-0 ml-2">02</h3>
									<div style="display: block; width: 5px; height: 5px;" class="bg-inversion mx-3 rounded-circle"></div>
									Completa tus requisitos
								</li>
								<li class="d-flex align-items-center bg-white rounded mb-2 px-md-5 px-md-2 py-3" style="font-size: 1.2rem;">
									<h3 width="20px" class="font-weight-bold mb-0 ml-2">03</h3>
									<div style="display: block; width: 5px; height: 5px;" class="bg-inversion mx-3 rounded-circle"></div>
									Elige en qué préstamo deseas invertir
								</li>
								<li class="d-flex align-items-center bg-white rounded mb-2 px-md-5 px-md-2 py-3" style="font-size: 1.2rem;">
									<h3 width="20px" class="font-weight-bold mb-0 ml-2">04</h3>
									<div style="display: block; width: 5px; height: 5px;" class="bg-inversion mx-3 rounded-circle"></div>
									Haz tu inversión
								</li>
								<li class="d-flex align-items-center bg-white rounded mb-2 px-md-5 px-md-2 py-3" style="font-size: 1.2rem;">
									<h3 width="20px" class="font-weight-bold mb-0 ml-2">05</h3>
									<div style="display: block; width: 5px; height: 5px;" class="bg-inversion mx-3 rounded-circle"></div>
									Recibe mensualmente en tu cuenta el rendimiento de tu inversión
								</li>
								<li class="d-flex align-items-center bg-white rounded mb-2 px-md-5 px-md-2 py-3" style="font-size: 1.2rem;">
									<h3 width="20px" class="font-weight-bold mb-0 ml-2">06</h3>
									<div style="display: block; width: 5px; height: 5px;" class="bg-inversion mx-3 rounded-circle"></div>
									Vuelve a invertir tus ganancias para hacer crecer aún más tu dinero
								</li>
							</ul>
						</div>
						<div class="col-3">
							<img src="@/assets/image/solicitante.webp" class="img-fluid" alt="Hombre solicitante">
						</div>
						<div class="col-6">
							<div class="text-center mt-3 pb-5">
								<router-link :to="{name:'opciones-inversion.index'}" class="btn btn-primary rounded-pill font-weight-bold">
									¡Empieza a Invertir!
								</router-link>
							</div>
						</div>
						<div class="col-3">
							<img src="@/assets/image/solicitante2.webp" class="img-fluid" alt="Mujer solicitante">
						</div>
					</div>
				</div>
			</div>
			<div v-show="tabIndex==2">
				<div class="d-flex justify-content-center position-relative p-3 p-md-5">
					<div class="col-lg-8">
						<div class="row justify-content-center">
							<div class="col-10 col-md-4 d-flex flex-column justify-content-center mb-3">
								<div class="overflow-hidden rounded-sm mb-3">
									<img src="@/assets/image/inversion.webp" class="img-fluid" alt="Inversión">
								</div>
								<br>
								<div class="text-center mb-3">
									<img src="@/assets/image/flechas_verdes.webp" class="img-fluid d-inline-block d-sm-none" style="width: 20px;" alt="Flecha">
									<img src="@/assets/image/arrow_down_white.webp" class="img-fluid d-none d-sm-inline-block" style="width: 20px;" alt="Flecha">
								</div>
								<div class="text-center mb-3">
									<img src="@/assets/image/mano.webp" class="img-fluid" style="width: 75px;" alt="Inversionista">
								</div>
								<br>
								<div class="text-center">
									<router-link :to="{name:'opciones-inversion.index'}" class="btn btn-primary rounded-pill font-weight-bold">
										¡Empieza a Invertir!
									</router-link>
								</div>
							</div>
							<div class="col-md-8 mb-3">
								<div class="card overflow-hidden rounded-lg bg-transparent">
									<div class="card-header p-2 bg-inversion">
										<h5 class="card-title mb-0 font-weight-bold mx-3">Requisitos</h5>
									</div>
									<div class="card-body bg-light">
										<div>
											<ul>
												<li>DPI vigente</li>
												<li>Recibo de agua, luz ó teléfono fijo</li>
												<li>Fondos a invertirse deben estar bancarizados</li>
											</ul>
											<p>
												<strong>
												Si eres una persona jurídica (todo lo anterior) y adicional a:
												</strong>
											</p>
											<ul>
												<li>Copia de nombramiento del representante legal</li>
												<li>DPI del representante legal</li>
												<li>Copia escritura constitución sociedad</li>
												<li>Copia de patentes de comercio y sociedad</li>
												<li>Copia de RTU de la empresa</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-show="tabIndex==3">
				<div class="form-row bg-inversion justify-content-center">
					<div class="col-md-6 p-3 p-md-5 text-center text-primary">
						<h2 class="mb-3">Las <strong>historias</strong> de <br>
						nuestros <strong class="text-primary">clientes</strong></h2>
						<div class="text-center">
							<img src="@/assets/image/arrow_down_white.webp" class="img-fluid" style="width: 20px;">
						</div>
					</div>
				</div>
				<div class="row justify-content-center py-3 px-5">
					<div class="col-lg-6 col-md-10 preguntas-carrousel beneficios-carrousel">
						<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
							<ol class="carousel-indicators">
								<li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
								<li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
								<li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
							</ol>
							<div class="carousel-inner">
								<div class="carousel-item active">
									<div class="form-row align-items-stretch justify-content-center">
										<div class="col-md-8 col-lg-6 mb-3 px-3">
											<div class="card bg-transparent border border-inversion rounded-lg h-100 position-relative">
												<div class="position-absolute p-2 bg-light" style="right: -20px; top: -2px;">
													<img src="@/assets/image/clientes_historias_inversionistas.webp" class="img-fluid" style="height: 40px;">
												</div>
												<div class="card-body pr-5" style="font-weight: 500;">
													<h5 class="card-title text-primary font-weight-bold">
													Hans, 80 años - Inversionista
													</h5>
													<p class="text-justify">
														Pensé que iba a ser más difícil pero resultó bastante sencillo, invertí mis ahorros en dos créditos prendarios y ahora tengo 4 inversiones. Obtuve un rendimiento de 24% anual.
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="carousel-item">
									<div class="form-row align-items-stretch justify-content-center">
										<div class="col-md-8 col-lg-6 mb-3 px-3">
											<div class="card bg-transparent border border-inversion rounded-lg h-100 position-relative">
												<div class="position-absolute p-2 bg-light" style="right: -20px; top: -2px;">
													<img src="@/assets/image/clientes_historias_inversionistas.webp" class="img-fluid" style="height: 40px;">
												</div>
												<div class="card-body pr-5" style="font-weight: 500;">
													<h5 class="card-title text-primary font-weight-bold">
													Diego, 33 años - Inversionista
													</h5>
													<p class="text-justify">
														La empresa familiar contaba con liquidez, nos presentaron la opción de invertir en este modelo. Anualmente genero de 5 a 6 veces lo que generaba en la cuenta de ahorros de la empresa.
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="carousel-item">
									<div class="form-row align-items-stretch justify-content-center">
										<div class="col-md-8 col-lg-6 mb-3 px-3">
											<div class="card bg-transparent border border-inversion rounded-lg h-100 position-relative">
												<div class="position-absolute p-2 bg-light" style="right: -20px; top: -2px;">
													<img src="@/assets/image/clientes_historias_inversionistas.webp" class="img-fluid" style="height: 40px;">
												</div>
												<div class="card-body pr-5" style="font-weight: 500;">
													<h5 class="card-title text-primary font-weight-bold">
													Carlos, 35 años - Inversionista
													</h5>
													<p class="text-justify">
														Una platafroma muy amigable y sencilla para el inversionista y cliente. Reconozco el apoyo y respuesta que me han brindado en gestiones solicitadas. Mi portafolio ya es de tres inversiones prendarias con retorno arriba del 36% anual.
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
								<span class="carousel-control-prev-icon" aria-hidden="true"></span>
								<span class="sr-only">Previous</span>
							</a>
							<a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
								<span class="carousel-control-next-icon" aria-hidden="true"></span>
								<span class="sr-only">Next</span>
							</a>
						</div>
					</div>
				</div>
				<div class="text-center pb-5 mt-3">
					<router-link :to="{name:'opciones-inversion.index'}" class="btn btn-primary rounded-pill font-weight-bold">
						¡Empieza a Invertir!
					</router-link>
				</div>
			</div>
			<div v-show="tabIndex==4">
				<div class="d-flex bg-primary p-3 p-md-5">
					<div class="col-md-12 text-center text-white">
						<div class="text-center mb-3">
							<img src="@/assets/image/preguntas_inversionista.webp" class="img-fluid" style="width: 50px;" alt="Preguntas frecuentes">
						</div>
						<h2>Preguntas Frecuentes</h2>
					</div>
				</div>
				<div>
					<div class="card">
						<a href="#" class="card-header" v-on:click.prevent="tabClick('primero', 'preguntas')" style="font-weight: 500;">
							<div class="d-flex align-items-center">
								<div class="mr-3">
									<div class="bg-primary rounded-circle text-center text-white p-2 font-weight-bold d-flex align-items-center justify-content-center" style="width: 35px; height: 35px; line-height: 18px">
										<small class="font-weight-bold">01</small>
									</div>
								</div>
								¿Cómo saber si tu inversión es de riesgo controlado?
								<div class="ml-auto d-flex align-items-center justify-content-center">
									<span class="material-icons float-right" v-if="preguntas.primero">expand_more</span>
									<span class="material-icons float-right" v-else>keyboard_arrow_right</span>
								</div>
							</div>
						</a>
						<div class="text-justify card-body" v-show="preguntas.primero">
							<ul>
								<li>Tu inversión SIEMPRE estará garantizada por un activo (vehículo o bien inmueble) que vale al menos el doble del monto invertido y por otro lado clubcashin.com realizó un análisis tanto del bien como de el deudor y la capacidad de pago del mismo. Clubcashin.com será el administrador único del préstamo.</li>
							</ul>
						</div>
					</div>
					<div class="card">
						<a href="#" class="card-header" v-on:click.prevent="tabClick('segundo', 'preguntas')" style="font-weight: 500;">
							<div class="d-flex align-items-center">
								<div class="mr-3">
									<div class="bg-primary rounded-circle text-center text-white p-2 font-weight-bold d-flex align-items-center justify-content-center" style="width: 35px; height: 35px; line-height: 18px">
										<small class="font-weight-bold">02</small>
									</div>
								</div>
								¿Montos mínimos y máximos para invertir?
								<div class="ml-auto d-flex align-items-center justify-content-center">
									<span class="material-icons float-right" v-if="preguntas.segundo">expand_more</span>
									<span class="material-icons float-right" v-else>keyboard_arrow_right</span>
								</div>
							</div>
						</a>
						<div class="card-body" v-show="preguntas.segundo">
							<ul class="text-justify">
								<li>No existen mínimos o máximos media vez el activo puesto en garantía del crédito valga al menos el doble de lo solicitado. Independientemente de los montos solicitados los créditos pueden ser fondeados por más de un inversor en la figura de <strong>“POOL”</strong>. En esta figura todos en partes proporcionales serán acreedores en primer lugar de ese crédito específico. Clubcashin. com será el administrador único del préstamo.</li>
							</ul>
						</div>
					</div>
					<div class="card">
						<a href="#" class="card-header" v-on:click.prevent="tabClick('tercero', 'preguntas')" style="font-weight: 500;">
							<div class="d-flex align-items-stretch">
								<div class="mr-3">
									<div class="bg-primary rounded-circle text-center text-white p-2 font-weight-bold d-flex align-items-center justify-content-center" style="width: 35px; height: 35px; line-height: 18px">
										<small class="font-weight-bold">03</small>
									</div>
								</div>
								¿Qué retorno puedo esperar?
								<div class="ml-auto d-flex align-items-center justify-content-center">
									<span class="material-icons float-right" v-if="preguntas.tercero">expand_more</span>
									<span class="material-icons float-right" v-else>keyboard_arrow_right</span>
								</div>
							</div>
						</a>
						<div class="card-body" v-show="preguntas.tercero">
							<ul class="text-justify">
								<li>Tasas de retorno anual: desde 10% anual (histórico marca ingresos hasta de 58.80% anual en algunos casos dependiendo de el tipo de crédito realizado).</li>
							</ul>
						</div>
					</div>
					<div class="card">
						<a href="#" class="card-header" v-on:click.prevent="tabClick('cuarto', 'preguntas')" style="font-weight: 500;">
							<div class="d-flex align-items-center">
								<div class="mr-3">
									<div class="bg-primary rounded-circle text-center text-white p-2 font-weight-bold d-flex align-items-center justify-content-center" style="width: 35px; height: 35px; line-height: 18px">
										<small class="font-weight-bold">04</small>
									</div>
								</div>
								Contamos con experiencia en el mercado financiero para ofrecer un soporte real y de riesgo controlado
								<div class="ml-auto d-flex align-items-center justify-content-center">
									<span class="material-icons float-right" v-if="preguntas.cuarto">expand_more</span>
									<span class="material-icons float-right" v-else>keyboard_arrow_right</span>
								</div>
							</div>
						</a>
						<div class="card-body" v-show="preguntas.cuarto">
							<ul class="text-justify">
			                    <li>Años de experiencia: + de 5 años de experiencia en créditos prendarios sobre vehículos</li>

			                    <li>Record Histórico Club Cash-In: Pérdida de capital 0% histórico</li>
			                  </ul>
						</div>
					</div>
					<div class="card">
						<a href="#" class="card-header" v-on:click.prevent="tabClick('quinto', 'preguntas')" style="font-weight: 500;">
							<div class="d-flex align-items-center">
								<div class="mr-3">
									<div class="bg-primary rounded-circle text-center text-white p-2 font-weight-bold d-flex align-items-center justify-content-center" style="width: 35px; height: 35px; line-height: 18px">
										<small class="font-weight-bold">05</small>
									</div>
								</div>
								¿Experiencia de Club Cash-In?
								<div class="ml-auto d-flex align-items-center justify-content-center">
									<span class="material-icons float-right" v-if="preguntas.quinto">expand_more</span>
									<span class="material-icons float-right" v-else>keyboard_arrow_right</span>
								</div>
							</div>
						</a>
						<div class="card-body" v-show="preguntas.quinto">
							<ul class="text-justify">
								<li>Club Cash-In es oficialmente la PRIMERA plataforma de créditos P2P (Peer to Peer)( de igual a igual) en el país, contando detrás de su estructura crediticia y de inversores con más de 20 años en el mercado de créditos prendarios sobre vehículos.</li>
							</ul>
						</div>
					</div>
					<div class="card">
						<a href="#" class="card-header" v-on:click.prevent="tabClick('sexto', 'preguntas')" style="font-weight: 500;">
							<div class="d-flex align-items-center">
								<div class="mr-3">
									<div class="bg-primary rounded-circle text-center text-white p-2 font-weight-bold d-flex align-items-center justify-content-center" style="width: 35px; height: 35px; line-height: 18px">
										<small class="font-weight-bold">06</small>
									</div>
								</div>
								¿Servicios ofrecidos por Club Cash-In?
								<div class="ml-auto d-flex align-items-center justify-content-center">
									<span class="material-icons float-right" v-if="preguntas.sexto">expand_more</span>
									<span class="material-icons float-right" v-else>keyboard_arrow_right</span>
								</div>
							</div>
						</a>
						<div class="card-body" v-show="preguntas.sexto">
							<ul class="text-justify">
								<li>Club Cash-In se encarga de llevar adelante desde la etapa inicial un crédito en nombre del solicitante de el mismo y de la parte inversora, iniciando por depurar a los diferentes solicitantes y sus bienes potencialmente puestos en garantía dentro de esta solicitud de financiamiento, haciendo un análisis exhaustivo de ambos tanto en las partes legales, fiscales, crediticias y valorando los bienes. Luego asesorando a ambas partes sobre como concretar el negocio de la mejor y más accesible manera, perfeccionando el mismo, llevando adelante la tarea de cobros y trámites legales durante el período que dure el crédito y si se llegará a una etapa de tener que entablar un proceso legal como sería una demanda o tomar posesión de un bien, Club Cash-In se encarga de lo mismo al 100% siempre velando de la manera más honesta y éticamente posible por ambas partes.</li>
							</ul>
						</div>
					</div>
					<div class="card">
						<a href="#" class="card-header" v-on:click.prevent="tabClick('septimo', 'preguntas')" style="font-weight: 500;">
							<div class="d-flex align-items-center">
								<div class="mr-3">
									<div class="bg-primary rounded-circle text-center text-white p-2 font-weight-bold d-flex align-items-center justify-content-center" style="width: 35px; height: 35px; line-height: 18px">
										<small class="font-weight-bold">07</small>
									</div>
								</div>
								¿Quiénes pueden invertir a través de Club Cash-In?
								<div class="ml-auto d-flex align-items-center justify-content-center">
									<span class="material-icons float-right" v-if="preguntas.septimo">expand_more</span>
									<span class="material-icons float-right" v-else>keyboard_arrow_right</span>
								</div>
							</div>
						</a>
						<div class="card-body" v-show="preguntas.septimo">
							<ul class="text-justify">
								<li>Puede hacerlo cualquier persona que desea invertir y poner a trabajar su dinero de manera inteligente con fondos de procedencia lícita y dentro de el sistema bancario. Mayor información al llenar documentación necesaria para realizar inversiones.</li>
								<li>Cualquier persona que desee obtener altos rendimientos y que su dinero trabaje para ellos es bienvenida a pertenecer a el prestigioso Club de Inversionistas de la región “Club Cash-In”.</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="text-center mt-3 pb-5">
					<router-link :to="{name:'opciones-inversion.index'}" class="btn btn-primary rounded-pill font-weight-bold">
						¡Empieza a Invertir!
					</router-link>
				</div>
			</div>
			<div v-show="tabIndex==5">
				<div class="row p-3 p-md-5 justify-content-center">
					<div class="col-md-12 text-center mb-3">
						<h2>Video Informativo</h2>
					</div>
					<div class="col-md-10 col-lg-6 text-center">
						<video width="100%" height="400" class="rounded img-fluid" src="https://clubcashin.com/img/CashIn_Usuario.mp4" controls>
							Tu navegador no soporta estos videos
						</video>
					</div>
				</div>
				<div class="text-center mt-3 pb-5">
					<router-link :to="{name:'opciones-inversion.index'}" class="btn btn-primary rounded-pill font-weight-bold">
						¡Empieza a Invertir!
					</router-link>
				</div>
			</div>
		</li>
	</ul>
</template>
<script type="text/javascript">
	export default {
		data(){
			return {
				tabIndex: 0,
				preguntas: {
					primero: true,
					segundo: false,
					tercero: false,
					cuarto: false,
					quinto: false,
					sexto: false,
					septimo: false
				},
				beneficios: {
					tab0: true,
					tab1: false,
					tab2: false,
					tab3: false,
					tab4: false
				}
			}
		},
		methods: {
			tabIndexClick(val){
				this.tabIndex = val
			},
			tabClick(index, tipo){
				this[tipo][index] = !this[tipo][index]
			}
		}
	}
</script>